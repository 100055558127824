import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistStore,
  persistReducer,
  getStoredState,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiSlice } from "./api/apiSlice";
import authRedux from "./features/auth/auth";
import counterRedux from "./features/counter/counter";

const rootReducers = combineReducers({
  auth: authRedux,
  counter: counterRedux,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "counter"],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const crossBrowserListener = (store, persistConfig) => {
  return async () => {
    let state = await getStoredState(persistConfig);

    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    });
  };
};

const setupStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

setupListeners(setupStore.dispatch);
const persistor = persistStore(setupStore);

window.addEventListener(
  "storage",
  crossBrowserListener(setupStore, persistConfig)
);

export { setupStore, persistor };
