import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { BreadcrumbsWidget } from "components";
import ImageUploading from "react-images-uploading";
import { useFormik } from "formik";
import Yup from "validation/Validation";
import { useEffect, useState } from "react";
import { AddPhotoAlternate, RemoveCircle } from "@mui/icons-material";
import { Pane, Spinner } from "evergreen-ui";
import { useGetCategoriesQuery } from "redux/api/slices/server";
import { addLogoAndBorderToImages } from "./imagefunctions";
import { useYukleMutation } from "api/topluYukle";
import { useGetLogosQuery } from "api/logos";
import Editor from "components/CKEditor/Editor";

const UploadProductPage = () => {
  const categories = useGetCategoriesQuery();
  const [ustKategori, setUstKategori] = useState(0);
  const initVal = {
    title: "",
    mdesc: "",
    meta_title: "",
    meta_description: "",
  };
  const [images, setImages] = useState([]);
  const [borderColor, setBorderColor] = useState("");
  const [yukle, yukleData] = useYukleMutation();

  const [seffaf, setSeffaf] = useState("");
  const [solLogo, setSolLogo] = useState("");
  const [aciklama, setAciklama] = useState("");
  const getLogos = useGetLogosQuery();

  const onChangeImages = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: initVal,
    validationSchema: Yup.object({
      title: Yup.string().required(),
      mdesc: Yup.string(),
      meta_title: Yup.string(),
      meta_description: Yup.string(),
    }),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      addLogoAndBorderToImages(images, seffaf, solLogo, borderColor)
        .then((processedImages) => {
          yukle({
            title: values.title,
            meta_title: values.meta_title,
            meta_description: values.meta_description,
            mdesc: values.mdesc,
            urun_aciklama: aciklama,
            imagesList: processedImages,
            categoryId: ustKategori,
          });
        })
        .catch((error) => {
          console.error("Hata oluştu: " + error);
        });
    },
  });

  useEffect(() => {
    if (getLogos.isSuccess) {
      setSeffaf(getLogos.data.seffaf);
      setSolLogo(getLogos.data.solLogo);
      setBorderColor(getLogos.data.borderColor);
    }
  }, [getLogos]);

  useEffect(() => {
    if (yukleData.isSuccess) {
      setImages([]);
      window.location.href = `${process.env.REACT_APP_PANEL_URL}/upload-product`;
    }
    if (yukleData.isError) {
      setImages([]);
      window.location.href = `${process.env.REACT_APP_PANEL_URL}/upload-product`;
    }
  }, [yukleData]);

  return (
    <div className="w-full my-6">
      <div className="w-full h-auto container mx-auto">
        <BreadcrumbsWidget activeItem="Toplu Ürün Yükle" />
        <div className="w-full h-auto flex flex-col p-5 gap-4 mb-10 border shadow bg-white">
          {yukleData.isLoading ? (
            <div className="w-full flex flex-wrap items-center justify-center gap-4">
              <Mui.LinearProgress sx={{ height: 10, width: "100%" }} />
              <h5 className="font-semibold text-gray-500 text-lg">
                Yükleniyor...
              </h5>
            </div>
          ) : (
            <>
              <Pane>
                {yukleData.isSuccess && (
                  <Mui.Alert severity="success">
                    <Mui.AlertTitle>{yukleData.data.message}</Mui.AlertTitle>
                    <strong>
                      {yukleData.data.count} Ürün başarılı bir şekilde yüklendi!
                    </strong>
                  </Mui.Alert>
                )}
                {yukleData.isError && (
                  <Mui.Alert severity="error">
                    <Mui.AlertTitle>Hata</Mui.AlertTitle>
                    <strong>
                      Ürünler yüklenirken bir hata oluştu Lütfen tekrar deneyin!
                    </strong>
                  </Mui.Alert>
                )}
              </Pane>
              <Mui.Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Mui.FormControl fullWidth sx={{ mb: 2 }}>
                  <Mui.TextField
                    sx={{ m: 0 }}
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Ürün Adı"
                    name="title"
                    autoComplete="title"
                    onChange={handleChange}
                    defaultValue={initVal.title}
                    key={initVal.title}
                    values={values.title}
                    helperText={errors.title && errors.title}
                    error={errors.title ? true : false}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth sx={{ mb: 2 }}>
                  {categories.isLoading && (
                    <div className="w-full flex justify-center items-center">
                      <Pane>
                        <Spinner marginX="auto" size={60} />
                      </Pane>
                    </div>
                  )}
                  {!categories.isLoading && (
                    <>
                      <Mui.InputLabel id="demo-simple-select-label">
                        Kategori
                      </Mui.InputLabel>
                      {!categories.isError && (
                        <Mui.Select
                          defaultValue=""
                          key={""}
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ustKategori}
                          label="Kategori"
                          onChange={(e) => setUstKategori(e.target.value)}
                        >
                          <Mui.MenuItem value={0}>
                            Kategori Seçiniz
                          </Mui.MenuItem>
                          {categories.data.length > 0 &&
                            categories.data.map((row, i) => (
                              <Mui.MenuItem key={i} value={row.id}>
                                {row.title}
                              </Mui.MenuItem>
                            ))}
                        </Mui.Select>
                      )}
                      {categories.isError && (
                        <Mui.Select
                          defaultValue={ustKategori}
                          key={ustKategori}
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ustKategori}
                          label="Üst Kategori"
                        >
                          <Mui.MenuItem value={0}>
                            Kategori Bulunamadı
                          </Mui.MenuItem>
                        </Mui.Select>
                      )}
                    </>
                  )}
                </Mui.FormControl>
                <Mui.FormControl fullWidth sx={{ mb: 2 }}>
                  <Mui.TextField
                    sx={{ m: 0 }}
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="meta_title"
                    label="Sayfa Title"
                    name="meta_title"
                    autoComplete="meta_title"
                    onChange={handleChange}
                    defaultValue={initVal.meta_title}
                    key={initVal.meta_title}
                    values={values.meta_title}
                    helperText={errors.meta_title && errors.meta_title}
                    error={errors.meta_title ? true : false}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth sx={{ mb: 2 }}>
                  <Mui.TextField
                    sx={{ m: 0 }}
                    size="small"
                    margin="normal"
                    fullWidth
                    multiline
                    rows={2}
                    id="mdesc"
                    label="Meta Description"
                    name="mdesc"
                    autoComplete="mdesc"
                    onChange={handleChange}
                    defaultValue={initVal.mdesc}
                    key={initVal.mdesc}
                    values={values.mdesc}
                    helperText={errors.mdesc && errors.mdesc}
                    error={errors.mdesc ? true : false}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth sx={{ mb: 2 }}>
                  <Mui.TextField
                    sx={{ m: 0 }}
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    rows={4}
                    id="meta_title"
                    label="Pinterest Yazısı"
                    name="meta_description"
                    autoComplete="meta_description"
                    onChange={handleChange}
                    defaultValue={initVal.meta_description}
                    key={initVal.meta_description}
                    values={values.meta_description}
                    helperText={
                      errors.meta_description && errors.meta_description
                    }
                    error={errors.meta_description ? true : false}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth sx={{ mb: 2 }}>
                  <Editor value={aciklama} setValue={setAciklama} />
                </Mui.FormControl>
                <div className="w-full">
                  <ImageUploading
                    multiple={true}
                    value={images}
                    onChange={onChangeImages}
                    dataURLKey="data_url"
                    acceptType={["jpg", "png", "webp", "jpeg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="w-full block">
                        <div className="w-full flex items-center gap-4">
                          <Mui.Button
                            size="small"
                            startIcon={<AddPhotoAlternate />}
                            variant="outlined"
                            sx={{ color: isDragging ? "red" : null }}
                            onClick={onImageUpload}
                            {...dragProps}
                            lang="tr"
                          >
                            Ürün Görselleri Yükle
                          </Mui.Button>
                          {images.length > 0 && (
                            <Mui.Button
                              size="small"
                              startIcon={<RemoveCircle />}
                              variant="outlined"
                              onClick={onImageRemoveAll}
                              lang="tr"
                            >
                              Tüm Görselleri Kaldır
                            </Mui.Button>
                          )}
                        </div>
                        <div className="uploaded-images-area">
                          <ul>
                            {imageList.map((image, i) => (
                              <li key={i}>
                                <div className="image">
                                  <img src={image.data_url} alt="" />
                                </div>
                                <div className="actions-buttons">
                                  <div className="btn-bottom">
                                    <button
                                      className="remove-photo"
                                      type="button"
                                      onClick={() => onImageRemove(i)}
                                    >
                                      Kaldır
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="w-full flex justify-end gap-4 mt-16 mb-32">
                  <Mui.FormControl>
                    <Mui.Button
                      startIcon={<MuiIcons.ArrowBack />}
                      size="small"
                      type="button"
                      href="/"
                      fullWidth
                      variant="contained"
                      lang={"tr"}
                    >
                      Geri Dön
                    </Mui.Button>
                  </Mui.FormControl>
                  <Mui.FormControl>
                    <Mui.Button
                      startIcon={<MuiIcons.CheckCircle />}
                      size="small"
                      type="submit"
                      fullWidth
                      variant="contained"
                      lang={"tr"}
                    >
                      Devam Et
                    </Mui.Button>
                  </Mui.FormControl>
                </div>
              </Mui.Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadProductPage;
