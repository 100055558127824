import { useCallback, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Button } from "@mui/material";

const DataTableWrap = ({
  title,
  columns,
  initialData,
  externalFunction,
  deleteRow = true,
  setParentList,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState(initialData);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (window.confirm(`Seçilen kayıtları silmek istediğinize emin misiniz?`)) {
      setToggleCleared(!toggleCleared);

      if (externalFunction) {
        externalFunction(selectedRows);
      }

      const updatedData = data.filter(
        (record) => !selectedRows.includes(record)
      );
      setData(updatedData);
      if (setParentList) {
        setParentList(updatedData);
      }
    }
  };

  const CustomContext = ({ selectedCount }) => {
    return (
      <div className="w-full flex items-center justify-between">
        <h5>{selectedCount} Kayıt Seçildi</h5>
        <div>
          <Button
            lang="tr"
            variant="outlined"
            color="error"
            onClick={handleDelete}
          >
            Seçilen Kayıtları Sil
          </Button>
        </div>
      </div>
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Gösterilecek Kayıt Sayısı:",
    rangeSeparatorText: ". Sayfadan",
    noRowsPerPage: false,
    selectAllRowsItem: false,
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  return (
    <DataTable
      title={
        <p className="w-full p-0 m-0 text-sm py-1 uppercase border-b" lang="tr">
          {title}
        </p>
      }
      columns={columns}
      data={data}
      selectableRows={deleteRow ? true : false}
      selectableRowsVisibleOnly={deleteRow ? true : false}
      contextComponent={deleteRow ? <CustomContext /> : false}
      clearSelectedRows={deleteRow ? toggleCleared : false}
      onSelectedRowsChange={deleteRow ? handleRowSelected : false}
      pagination={true}
      highlightOnHover={true}
      striped={true}
      paginationPerPage={20}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent="Kayıt yok"
    />
  );
};

export default DataTableWrap;
