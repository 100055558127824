import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { loggedOut } from "redux/features/auth/auth";
import { userMemo } from "redux/features/auth/auth";

const HeaderSection = () => {
  const user = useSelector(userMemo);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loggedOut());
  };

  return (
    <header className="w-full block shadow border-b border-gray-400 bg-[#2c83d4] text-white">
      <div className="container mx-auto">
        <div className="w-full flex justify-between items-center gap-6">
          <div className="flex-1 flex items-center gap-4">
            <div className="flex items-center gap-3 w-80 border-r">
              <div className="flex items-center gap-2 text-sm">
                <span>
                  <AccountCircleIcon />
                </span>
                <span className="block">Giriş yapan kullanıcı:</span>
              </div>
              <div className="block font-bold">{user?.username}</div>
            </div>
            <div className="w-full block">
              <ul className="navbar">
                <li lang="tr">
                  <a href="/">Ana Ekran</a>
                </li>
                <li lang="tr">
                  <a href="/upload-product">Toplu Ürün Yükle</a>
                </li>
                <li lang="tr">
                  <a href="/logos">Logolar</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="block">
            <Button
              color="error"
              onClick={handleLogout}
              variant="contained"
              size="small"
              endIcon={<LogoutIcon />}
            >
              Çıkış Yap
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderSection;
