import { apiSlice } from "redux/api/apiSlice";

export const logos = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    LogosAction: builder.mutation({
      query: (data) => ({
        url: `/logos-action`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    getLogos: builder.query({
      query: () => `/get-logos`,
      providesTags: ["Modul"],
    }),
  }),
});

export const { useLogosActionMutation, useGetLogosQuery } = logos;
