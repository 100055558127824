import * as Page from "pages";
import { AuthLayout, GeneralLayout } from "utils";
import PrivateRoute from "./PrivateRoute";

const routes = [
  {
    path: "/",
    element: <GeneralLayout />,
    children: [
      {
        index: true,
        element: <Page.DashboardPage />,
        auth: true,
      },
      {
        path: "logos",
        element: <Page.LogosPage />,
        auth: true,
      },
      {
        path: "upload-product",
        element: <Page.UploadProductPage />,
        auth: true,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <Page.LoginPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Page.NotFoundPage />,
  },
];

const authMap = (routes) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = <PrivateRoute>{route.element}</PrivateRoute>;
    }
    if (route?.children) {
      route.children = authMap(route.children);
    }
    return route;
  });

export default authMap(routes);
