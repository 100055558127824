// import seffaf from "../../logolar/seffaf-logo.png";
// import solLogo from "../../logolar/sol-logo.png";

// Logo eklemeyi ve çerçeve eklemeyi birleştiren işlev
export const addLogoAndBorderToImages = async (
  images,
  seffaf,
  solLogo,
  borderColor
) => {
  const processedImages = [];

  function processImage(base64Image) {
    return new Promise((resolve, reject) => {
      // Resmi yeniden boyutlandır
      resizeImage(base64Image.data_url, 1200, 950)
        .then((resizedImage) => {
          // Önce çerçeve ekleyelim, sonra logo ekleyelim
          addBorderToImage(resizedImage, 10, borderColor)
            .then((imageWithBorder) => {
              addLogoToImage(imageWithBorder, seffaf, solLogo)
                .then((finalImage) => {
                  processedImages.push(finalImage);
                  resolve();
                })
                .catch(reject);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  const imagePromises = images.map(processImage);

  return Promise.all(imagePromises).then(() => {
    return processedImages;
  });
};

// Resmi yeniden boyutlandıran işlev
const resizeImage = (base64Image, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      // Kaliteyi düşürmeden webp formatına dönüştür
      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        },
        "image/webp",
        1 // Kalite faktörü, 1 maksimum kaliteyi temsil eder
      );
    };

    img.onerror = reject;
    img.src = base64Image;
  });
};

// Logo ekleyen işlev
export const addLogoToImage = (base64Image, logo1Url, logo2Url) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const image = new Image();
    const logo1 = new Image();
    const logo2 = new Image();

    image.onload = function () {
      canvas.width = image.width;
      canvas.height = image.height;

      context.drawImage(image, 0, 0, image.width, image.height);

      logo1.onload = function () {
        const centerX = (canvas.width - logo1.width) / 2;
        const centerY = (canvas.height - logo1.height) / 2;

        context.drawImage(logo1, centerX, centerY, logo1.width, logo1.height);

        logo2.onload = function () {
          const logo2X = 10;
          const logo2Y = canvas.height - logo2.height - 10;

          context.drawImage(logo2, logo2X, logo2Y, logo2.width, logo2.height);

          // Kaliteyi düşürmeden webp formatına dönüştür
          canvas.toBlob(
            (blob) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.readAsDataURL(blob);
            },
            "image/webp",
            1
          );
        };

        logo2.src = logo2Url;
      };

      logo1.src = logo1Url;
    };

    image.src = base64Image;
  });
};

// Çerçeve ekleyen işlev
export const addBorderToImage = (base64Image, borderWidth, borderColor) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const image = new Image();

    image.onload = function () {
      canvas.width = image.width + 2 * borderWidth;
      canvas.height = image.height + 2 * borderWidth;

      context.fillStyle = borderColor;
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.drawImage(
        image,
        borderWidth,
        borderWidth,
        image.width,
        image.height
      );

      // Kaliteyi düşürmeden webp formatına dönüştür
      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        },
        "image/webp",
        1
      );
    };

    image.src = base64Image;
  });
};
