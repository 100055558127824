import { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { BreadcrumbsWidget } from "components";
import { useFormik } from "formik";
import Yup from "validation/Validation";
import { useGetLogosQuery, useLogosActionMutation } from "api/logos";

const LogosPage = () => {
  const [seffaf, setSeffaf] = useState("");
  const [solLogo, setSolLogo] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [LogosAction, logosData] = useLogosActionMutation();
  const getLogos = useGetLogosQuery();

  const handleTransparentLogoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64Data = e.target.result;
      setSeffaf(base64Data);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSolidLogoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64Data = e.target.result;
      setSolLogo(base64Data);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      seffaf: seffaf,
      solLogo: solLogo,
      borderColor: borderColor,
    },
    validationSchema: Yup.object({
      borderColor: Yup.string(),
      seffaf: Yup.string(),
      solLogo: Yup.string(),
    }),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      LogosAction({
        seffaf: seffaf,
        solLogo: solLogo,
        borderColor: values.borderColor,
      });
    },
  });

  useEffect(() => {
    // console.log(seffaf);
    if (getLogos.isSuccess) {
      // console.log(getLogos.data);
      setSeffaf(getLogos.data.seffaf);
      setSolLogo(getLogos.data.solLogo);
      setBorderColor(getLogos.data.borderColor);
    }
  }, [getLogos]);

  return (
    <div className="w-full my-6">
      <div className="w-full h-auto container mx-auto">
        <BreadcrumbsWidget activeItem="Logolar" />
        <div className="w-full h-auto flex flex-col p-5 gap-4 border shadow bg-white">
          {logosData.isLoading ? (
            <div className="w-full flex flex-wrap items-center justify-center gap-4">
              <Mui.LinearProgress sx={{ height: 10, width: "100%" }} />
              <h5 className="font-semibold text-gray-500 text-lg">
                Kaydediliyor...
              </h5>
            </div>
          ) : (
            <>
              {logosData.isError && (
                <Mui.Alert severity="error">
                  <Mui.AlertTitle>Hata</Mui.AlertTitle>
                  <strong>{logosData.error.message}</strong>
                </Mui.Alert>
              )}
              {logosData.isSuccess && (
                <Mui.Alert severity="success">
                  <Mui.AlertTitle>Başarılı</Mui.AlertTitle>
                  <strong>{logosData.data.message}</strong>
                </Mui.Alert>
              )}
              <Mui.Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <div>
                  <Mui.FormControl fullWidth sx={{ mb: 2 }}>
                    <Mui.TextField
                      sx={{ m: 0 }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="borderColor"
                      label="Border Color"
                      name="borderColor"
                      autoComplete="borderColor"
                      onChange={handleChange}
                      defaultValue={borderColor}
                      key={borderColor}
                      values={values.borderColor}
                      helperText={errors.borderColor && errors.borderColor}
                      error={errors.borderColor ? true : false}
                    />
                  </Mui.FormControl>
                </div>
                <div className="w-full grid grid-cols-2 gap-4">
                  <div className="w-full flex gap-3">
                    <div className="after:content-[':'] text-xl">
                      Şeffaf Logo
                    </div>
                    <div className="flex flex-col gap-4">
                      <input
                        className="text-sm"
                        type="file"
                        accept="image/*"
                        onChange={handleTransparentLogoChange}
                      />
                      <img
                        className="bg-black"
                        src={seffaf}
                        alt="Şeffaf Logo"
                      />
                    </div>
                  </div>
                  <div className="w-full flex gap-3">
                    <div className="after:content-[':'] text-xl">Sol Logo</div>
                    <div className="flex flex-col gap-4">
                      <input
                        className="text-sm"
                        type="file"
                        accept="image/*"
                        onChange={handleSolidLogoChange}
                      />
                      <img src={solLogo} alt="Sol Logo" />
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-end gap-4 mt-16">
                  <Mui.FormControl>
                    <Mui.Button
                      startIcon={<MuiIcons.ArrowBack />}
                      size="small"
                      type="button"
                      href="/"
                      fullWidth
                      variant="contained"
                      lang={"tr"}
                    >
                      Geri Dön
                    </Mui.Button>
                  </Mui.FormControl>
                  <Mui.FormControl>
                    <Mui.Button
                      startIcon={<MuiIcons.CheckCircle />}
                      size="small"
                      type="submit"
                      fullWidth
                      variant="contained"
                      lang={"tr"}
                    >
                      Devam Et
                    </Mui.Button>
                  </Mui.FormControl>
                </div>
              </Mui.Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogosPage;
