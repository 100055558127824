import { createSlice, createSelector } from "@reduxjs/toolkit";

const initStateAuht = {
  auth: false,
  user: null,
  accessToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initStateAuht,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      state.auth = true;
      state.user = user;
      state.accessToken = accessToken;
    },
    loggedOut: (state) => {
      state.auth = false;
      state.user = null;
      state.accessToken = null;
    },
  },
});

export const auhtMemo = createSelector(
  (state) => state.auth,
  (auth) => {
    return auth;
  }
);

export const tokenMemo = createSelector(
  (state) => state.auth.accessToken,
  (accessToken) => {
    return accessToken;
  }
);

export const userMemo = createSelector(
  (state) => state.auth.user,
  (user) => {
    return user;
  }
);

export const { setCredentials, loggedOut } = authSlice.actions;
export default authSlice.reducer;
