import { apiSlice } from "redux/api/apiSlice";

export const topluYukle = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    yukle: builder.mutation({
      query: (data) => ({
        url: `/toplu-yukle`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
  }),
});

export const { useYukleMutation } = topluYukle;
