import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auhtMemo, tokenMemo, loggedOut } from "redux/features/auth/auth";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();

  const accessToken = useSelector(tokenMemo);
  const { auth } = useSelector(auhtMemo);

  useEffect(() => {
    if (accessToken) {
      if (!auth) {
        dispatch(loggedOut());
      }
    } else {
      dispatch(loggedOut());
    }
  }, [accessToken, auth, dispatch]);

  if (!auth) {
    return <Navigate to="/auth/login" replace={true} />;
  } else {
    return children;
  }
};

export default PrivateRoute;
