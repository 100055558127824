import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { HeaderSection } from "components";

const GeneralLayout = () => {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="w-full h-full">
        <HeaderSection />
        <Outlet />
      </div>
    </>
  );
};

export default GeneralLayout;
