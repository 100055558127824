import { createSlice } from "@reduxjs/toolkit";


const initStateCounter = {
  counter: 0,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState: initStateCounter,
  reducers: {
    arttir: (state, action) => {
      state.counter = action.payload;
    },
    azalt: (state, action) => {
      state.counter = action.payload;
    },
  },
});

export const { arttir, azalt } = counterSlice.actions;
export default counterSlice.reducer;
