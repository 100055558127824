import { useEffect } from "react";
import {
  Avatar,
  CssBaseline,
  Paper,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginForm from "./LoginForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auhtMemo, tokenMemo, userMemo } from "redux/features/auth/auth";

const theme = createTheme();

const LoginPage = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(tokenMemo);
  const { auth } = useSelector(auhtMemo);
  const user = useSelector(userMemo);

  useEffect(() => {
    if (accessToken) {
      if (auth) {
        if (user.seciliKurum !== null) {
          navigate("/");
        }
      }
    }
  }, [navigate, accessToken, user, auth]);

  return (
    <div className="w-full px-4 md:w-2/3 m-auto py-6">
      <ThemeProvider theme={theme}>
        <Grid container component="main">
          <CssBaseline />
          <Grid
            item
            xs={12}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Giriş Yap
              </Typography>
              <LoginForm />
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default LoginPage;
