import { Typography, Breadcrumbs, Link } from "@mui/material";

const BreadcrumbsWidget = ({ data, activeItem }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      <Link underline="hover" color="inherit" href="/">
        Anasayfa
      </Link>
      {data &&
        data.map((row, i) => (
          <Link key={i} underline="hover" color="inherit" href={row.url}>
            {row.title}
          </Link>
        ))}
      <Typography color="text.primary">{activeItem}</Typography>
    </Breadcrumbs>
  );
};

export default BreadcrumbsWidget;
