import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecordsCounts: builder.query({
      query: () => `/getrecordscounts`,
      providesTags: ["Modul"],
    }),
    getArticles: builder.query({
      query: () => `/articles`,
      providesTags: ["Modul"],
    }),
    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `/delete-article`,
        method: "POST",
        body: id,
      }),
      invalidatesTags: ["Modul"],
    }),
    getCategories: builder.query({
      query: () => `/get-categories`,
      providesTags: ["Modul"],
    }),
    insertArticle: builder.mutation({
      query: (data) => ({
        url: `/insert-article`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    updateArticle: builder.mutation({
      query: (data) => ({
        url: `/update-article`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    singleArticle: builder.mutation({
      query: (data) => ({
        url: `/single-article`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    getProducts: builder.mutation({
      query: (data) => ({
        url: `/products`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    addProducts: builder.mutation({
      query: (data) => ({
        url: `/add-product`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    singleProduct: builder.mutation({
      query: (data) => ({
        url: `/single-product`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    updateProduct: builder.mutation({
      query: (data) => ({
        url: `/update-product`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    deleteProduct: builder.mutation({
      query: (data) => ({
        url: `/delete-product`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    addCategory: builder.mutation({
      query: (data) => ({
        url: `/add-categories`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    singleCategory: builder.mutation({
      query: (data) => ({
        url: `/single-category`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    updateCategory: builder.mutation({
      query: (data) => ({
        url: `/update-categories`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Modul"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/delete-category`,
        method: "POST",
        body: id,
      }),
      invalidatesTags: ["Modul"],
    }),
    setArticleCategory: builder.mutation({
      query: (id) => ({
        url: `/set-article-category`,
        method: "POST",
        body: id,
      }),
      invalidatesTags: ["Modul"],
    }),
    removeArticleCategory: builder.mutation({
      query: (id) => ({
        url: `/remove-article-category`,
        method: "POST",
        body: id,
      }),
      invalidatesTags: ["Modul"],
    }),
    checkExists: builder.mutation({
      query: (id) => ({
        url: `/check-exists`,
        method: "POST",
        body: id,
      }),
      invalidatesTags: ["Modul"],
    }),
    kategoriler: builder.query({
      query: () => `/kategoriler`,
      providesTags: ["Modul"],
    }),
  }),
});

export const {
  useGetRecordsCountsQuery,
  useGetArticlesQuery,
  useDeleteArticleMutation,
  useGetCategoriesQuery,
  useInsertArticleMutation,
  useUpdateArticleMutation,
  useSingleArticleMutation,
  useGetProductsMutation,
  useAddProductsMutation,
  useSingleProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useSingleCategoryMutation,
  useDeleteCategoryMutation,
  useSetArticleCategoryMutation,
  useRemoveArticleCategoryMutation,
  useCheckExistsMutation,
  useKategorilerQuery,
} = authApiSlice;
