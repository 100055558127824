import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (KullaniciBilgileri) => ({
        url: `/login`,
        method: "POST",
        body: { ...KullaniciBilgileri },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
