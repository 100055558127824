import { Typography, Breadcrumbs, Link } from "@mui/material";

const DashboardPage = () => {
  return (
    <div className="w-full my-6">
      <div className="w-full h-auto container mx-auto">
        <div className="w-full mb-6">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Ana Ekran
            </Link>
            <Typography color="text.primary">Ana Ekran</Typography>
          </Breadcrumbs>
        </div>
        <div className="w-full flex items-center gap-2 p-3 mb-6 border shadow bg-white">
          <Typography variant="body1" component="div">
            Site:
          </Typography>
          <Typography variant="body1" component="div">
            {process.env.REACT_APP_SITE}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
