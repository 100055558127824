import { useEffect } from "react";
import { Button, TextField, Box, Alert } from "@mui/material";
import { useFormik } from "formik";
import Yup from "validation/Validation";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "redux/api/slices/authApiSlice";
import { setCredentials } from "redux/features/auth/auth";
import toast from "react-hot-toast";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    kullaniciKodu: Yup.string().required(),
    sifre: Yup.string().required(),
  });

  const [login, { data, isLoading, isSuccess, isError, error }] =
    useLoginMutation();

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      kullaniciKodu: "",
      sifre: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      login({ user: values.kullaniciKodu, password: values.sifre });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setCredentials({
          user: data.user,
          accessToken: data.accessToken,
        })
      );
      toast.success("Başarıyla giriş yaptınız!", { duration: 6000 });
    }
  }, [data, dispatch, navigate, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(error.data?.message, { duration: 4000 });
    }
  }, [error, isError]);

  if (isLoading) {
    return <h1>Giriş Yapılıyor...</h1>;
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <div className="block w-full h-auto">
        {errors.kullaniciKodu && (
          <Alert severity="error">{errors.kullaniciKodu}</Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="kullaniciKodu"
          label="Kullanıcı Kodu"
          name="kullaniciKodu"
          autoComplete="kullaniciKodu"
          onChange={handleChange}
          values={values.kullaniciKodu}
          autoFocus
        />
        {errors.sifre && <Alert severity="error">{errors.sifre}</Alert>}
        <TextField
          margin="normal"
          required
          fullWidth
          name="sifre"
          label="Şifre"
          type="password"
          id="sifre"
          onChange={handleChange}
          values={values.sifre}
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          lang={"tr"}
        >
          Giriş Yap
        </Button>
      </div>
    </Box>
  );
};

export default LoginForm;
