import * as Yup from "yup";

Yup.setLocale({
  mixed: {
    required: "Bu alan zorunludur.",
    notType: "Bu alana sayı gerilmelidir.",
  },
  string: {
    min: ({ min }) => `Bu alan minimum ${min} karakter içermelidir.`,
  },
});

export default Yup;
